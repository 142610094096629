import { render, staticRenderFns } from "./detailModal.vue?vue&type=template&id=48d47008&scoped=true&"
import script from "./detailModal.vue?vue&type=script&lang=js&"
export * from "./detailModal.vue?vue&type=script&lang=js&"
import style0 from "./detailModal.vue?vue&type=style&index=0&id=48d47008&lang=scss&scoped=true&"
import style1 from "./detailModal.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48d47008",
  null
  
)

export default component.exports