<template>
    <div>
        <div style="min-height:50px">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-collapse v-model="activeKey" accordion>
                <a-collapse-panel v-for="(item,index) in list" :key="`${index+1}`" :header="`【${item.created_at}】「版本${item.smartservice_version}」 ${item.created_by}  ${item.smartservice_subject}`">
                    <code-diff 
                        :old-string="item.smartservice_content_before"
                        :new-string="item.smartservice_content_after" 
                        :context="5"
                        diffStyle="char"
                    />
                </a-collapse-panel>
            </a-collapse>
        </div>
    </div>
</template>

<script>
    import CodeDiff from 'vue-code-diff'
    export default {
        name:'contents',
        components: {CodeDiff},
        data() {
            return {
                loading:false,
                activeKey:[],
                list:[]
            }
        },
        props:{
            smartservice_id:String,
        },
        created () {
            this.getList()
        },
        methods: {
            async getList() {
                this.loading = true
                await this.$store.dispatch('smartserviceVersionsAction',{data:{smartservice_id:this.smartservice_id}})
                .then(res=>{
                    this.list = res.items
                    this.loading = false
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>